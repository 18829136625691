export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter'
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/blogg',
      id: process.env.REACT_APP_BLOG_ID || 218,
      mainLevel: 1,
      menu: true
    },
    brand: {
      id: 154
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  addwish: {
    productPage: [
      'k60b737b5a5549d7c36b8dc05',
      'k60b7379aa5549d7c36b8d0eb',
      'k5fa401dd44cf6b0486d0a56a'
    ],
    categoryPage: 'k60b73746a5549d7c36b8a9bc'
  },
  settings: {
    storeName: 'PromixSweden',
    siteContentId: 137
  }
};
