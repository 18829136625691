import React from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import FooterContent from './FooterContent';
import NewsletterBox from './NewsletterBox';
import { theme } from '../../Theming/Theming';

const FooterWrapper = styled('footer')`
  background: ${theme.footer.background};
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    padding: 3rem 0 0;
    color: ${theme.footer.color};
  }
`;

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContent />
          </MaxWidth>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
