import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import { getFontSize } from '../Theming/helpers';
import { domLink } from '../ui/TextContent';
import parse from 'html-react-parser';

export const contentTextElements =
  'h1, h2, h3, h4, h5, h6, p, span, li, button.read-more';

export const ContentStyles = styled.div`
  //IMAGE HOVER EFFECT
  .image-wrapper {
    position: relative;
    &:before {
      transition: opacity 0.2s;
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: #000;
      opacity: 0;
      z-index: 1;
    }
    &:hover {
      &:before {
        opacity: 0.1;
      }
    }
  }
`;

const ContentHtmlWrapper = styled('div')`
  &.content-html-text {
    display: block;
    overflow: hidden;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-transform: ${theme.startPage.headers.textTransform};
      font-family: ${theme.startPage.headers.fontFamily};
      font-weight: ${theme.startPage.headers.fontWeight};
      line-height: 1.2;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &.light {
      ${contentTextElements} {
        color: white;
      }
    }

    &.force-under {
      ${theme.below.sm} {
        ${contentTextElements} {
          color: ${theme.colors.font};
        }
      }
    }

    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }

    h1 {
      ${getFontSize(theme.startPage.headers.fontSize[1])}
    }
    h2 {
      ${getFontSize(theme.startPage.headers.fontSize[2])}
    }
    h3 {
      ${getFontSize(theme.startPage.headers.fontSize[3])}
    }
    h4 {
      ${getFontSize(theme.startPage.headers.fontSize[4])}
    }
    h5 {
      ${getFontSize(theme.startPage.headers.fontSize[5])}
    }

    video {
      width: 100%;
    }

    //CTA-BUTTON
    button:not(.read-more) {
      ${getFontSize(theme.startPage.cta.fontSize)};
      border: ${theme.startPage.cta.border};
      color: ${theme.startPage.cta.color};
      background: ${theme.startPage.cta.background};
      font-family: ${theme.startPage.cta.fontFamily};
      font-weight: ${theme.startPage.cta.fontWeight};
      display: inline-block;
      text-align: center;
      text-transform: ${theme.startPage.cta.textTransform};
      letter-spacing: ${theme.startPage.cta.letterSpacing};
      margin-top: 1rem;
      padding: 0.7rem 2rem;
      transition: all 0.3s;
      margin-top: 1rem;
      ${theme.above.md} {
        margin-top: 2rem;
        padding: 0.9rem 3rem;
      }
      a,
      a:active {
        color: ${theme.startPage.cta.color};
      }
      &:hover {
        color: ${theme.startPage.cta.hover.color};
        background: ${theme.startPage.cta.hover.background};
        border: ${theme.startPage.cta.hover.border};
        a,
        a:active {
          color: ${theme.startPage.cta.hover.color};
        }
      }
      &.secondary {
        color: ${theme.startPage.cta.secondary.color};
        background: ${theme.startPage.cta.secondary.background};
        border: ${theme.startPage.cta.secondary.border};
        a,
        a:active {
          color: ${theme.startPage.cta.secondary.color};
        }
        &:hover {
          color: ${theme.startPage.cta.secondary.hover.color};
          background: ${theme.startPage.cta.secondary.hover.background};
          border: ${theme.startPage.cta.secondary.hover.border};
          a,
          a:active {
            color: ${theme.startPage.cta.secondary.hover.color};
          }
        }
      }
      &.third {
        color: ${theme.startPage.cta.third.color};
        background: ${theme.startPage.cta.third.background};
        border: ${theme.startPage.cta.third.border};
        a,
        a:active {
          color: ${theme.startPage.cta.third.color};
        }
        &:hover {
          color: ${theme.startPage.cta.third.hover.color};
          background: ${theme.startPage.cta.third.hover.background};
          border: ${theme.startPage.cta.third.hover.border};
          a,
          a:active {
            color: ${theme.startPage.cta.third.hover.color};
          }
        }
      }
    }
    //CTA BUTTON
    button.read-more {
      font-family: ${theme.startPage.cta.fontFamily};
      font-weight: ${theme.startPage.cta.fontWeight};
      margin-top: 1rem;
      text-decoration: underline;
      font-size: 1.2rem;
    }
  }
`;

const ContentHtmlParse = ({ content }) => {
  if (!content) return null;
  const options = {
    replace: domNode => {
      if (domNode && !domNode.attribs) return;
      return domLink({ domNode, options });
    }
  };
  return parse(content, options);
};

export const ContentHtml = ({ content, className = '', ...props }) => {
  return (
    <ContentHtmlWrapper className={`content-html-text ${className}`} {...props}>
      <ContentHtmlParse content={content} />
    </ContentHtmlWrapper>
  );
};
